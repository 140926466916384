/* eslint-disable indent */
import {
    FormControl, InputLabel, MenuItem, Select, Box,
  } from '@mui/material';
  import React, { useState } from 'react';
  import { useEffectAsync } from '../../reactHelper';

  const SelectFieldMap = ({
    label,
    multiple,
    value,
    emptyValue = null,
    emptyTitle = '\u00a0',
    onChange,
    endpoint,
    data,
    keyGetter = (item) => item.id,
    titleGetter = (item) => item.name,
  }) => {
    const [items, setItems] = useState(data);

    useEffectAsync(async () => {
      if (endpoint) {
        const response = await fetch(endpoint);
        if (response.ok) {
          setItems(await response.json());
        } else {
          throw Error(await response.text());
        }
      }
    }, []);

    if (items) {
      return (
        <Box mt={-2} mb={2} display="flex" justifyContent="center">
          <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select
              sx={{ width: 180 }}
              label={label}
              multiple={multiple}
              value={value}
              onChange={onChange}
            >
              {!multiple && emptyValue !== null
              && <MenuItem value={emptyValue}>{emptyTitle}</MenuItem>}
              {items.map((item) => (
                <MenuItem key={keyGetter(item)} value={keyGetter(item)}>{titleGetter(item)}</MenuItem>
            ))}
            </Select>
          </FormControl>
        </Box>
      );
    }
    return null;
  };

  export default SelectFieldMap;
