import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Stack from '@mui/material/Stack';
import EditItemView from './components/EditItemView';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));
const DriverPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () => item && item.name && item.uniqueId && item.permitNo;
  const [number, setNumber] = useState('');
  const [body, setBody] = useState('');

  const onClicksms = async (e) => {
    await e.preventDefault();

    const res = await fetch('/api/sendMessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ to: number, body }),
    });

    const data = await res.json();
    if (data.success) {
      await setNumber('');
      await setBody('');
    } else {
      await setNumber('An Error has occurred.');
      await setBody('An Error has occurred.');
    }
  };
  return (
    <EditItemView
      endpoint="drivers"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
              <TextField
                value={item.uniqueId || ''}
                onChange={(event) => setItem({ ...item, uniqueId: event.target.value })}
                label={t('deviceIdentifier')}
              />
              <TextField
                value={item.permitNo || ''}
                onChange={(event) => setItem({ ...item, permitNo: event.target.value })}
                label={t('permitNo')}
              />
              <TextField
                id="date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={item.permitExp || ''}
                onChange={(event) => setItem({ ...item, permitExp: event.target.value })}
                label="Permit Expiry Date"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedUpdateAccess')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className="smartAccess">
                <Stack direction="row" spacing={2}>
                  <Button variant="outlined" color="success" startIcon={<PersonAddAlt1Icon />} onClick={(event) => onClicksms({ ...item, uniqueId: event.target.value })}>
                    Assign ID
                  </Button>
                  <Button variant="outlined" color="error" startIcon={<PersonRemoveIcon />} onClick={(event) => onClicksms({ ...item, uniqueId: event.target.value })}>
                    Revoke ID
                  </Button>
                </Stack>
                {/* {console.log(`Number is ${number} and the Message is ${body}`)} */}
              </div>
            </AccordionDetails>
          </Accordion>
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{}}
          />
        </>
      )}
    </EditItemView>
  );
};

export default DriverPage;
