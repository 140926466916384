/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Button, Typography, IconButton, Card, CardContent, CardActions, CardMedia,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  plansContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  card: {
    width: '300px',
    marginBottom: theme.spacing(2),
  },
  cardMedia: {
    height: '140px',
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

const PlansPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();

  // Demo plans data
  const plans = [
    {
      id: 1,
      name: 'Basic Plan',
      description: 'Ideal for small teams and startups.',
      price: '$10',
      features: ['Feature 1', 'Feature 2', 'Feature 3'],
    },
    {
      id: 2,
      name: 'Pro Plan',
      description: 'For growing businesses and professionals.',
      price: '$20',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    },
    {
      id: 3,
      name: 'Premium Plan',
      description: 'For enterprises and large businesses.',
      price: '$30',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
    },
  ];

  const handlePlanSelection = (planId) => {
    // Navigate to payment page or process payment directly
    // For example, navigate to a payment page:
    navigate(`/payment?planId=${planId}`);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton className={classes.backButton} color="primary" onClick={() => navigate('/login')}>
          <ArrowBackIcon />
        </IconButton>
        <Typography className={classes.title} color="primary">
          {t('plansPageTitle')}
        </Typography>
      </div>
      <div className={classes.plansContainer}>
        {plans.map((plan) => (
          <Card key={plan.id} className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              image="https://source.unsplash.com/random"
              title={plan.name}
            />
            <CardContent>
              <Typography variant="h5" component="div">
                {plan.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {plan.description}
              </Typography>
              <Typography variant="body1" color="text.primary">
                {plan.price}
              </Typography>
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button size="small" onClick={() => handlePlanSelection(plan.id)}>
                Select Plan
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default PlansPage;
