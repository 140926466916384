/* eslint-disable no-unused-vars */
/**
 * @ Author: Mark Okello (mark@cynotechlabs.com)
 * @ Create Time: 2024-04-07 17:38:16
 * @ Modified by: Mark Okello
 * @ Modified time: 2024-06-27 07:58:43
 * @ Description:
 */

import React from 'react';
import { Snackbar, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from './LocalizationProvider';

const StyledSnackbar = styled(Snackbar)({
  '& .MuiSnackbarContent-root': {
    backgroundColor: '#138a17', // Set Snackbar background to transparent
    justifyContent: 'center',
    alignItems: 'center',
  },
  '&.MuiSnackbarContent-message': {
    textAlign: 'center',
    width: '100%',
  },
});

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
}));

const SuccessDialog = ({ open, onClose, message }) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      message={message} // Use the passed message prop
      ContentProps={{ className: classes.success }}
    />
  );
};

export default SuccessDialog;
