/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from './LocalizationProvider';
import BaseCommandView from '../../settings/components/BaseCommandView';
import { useCatch } from '../../reactHelper';
import SuccessDialog from './SuccessDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  dialog: {
    borderRadius: 32, // Adjust the value as needed
    fontSize: 1,
  },
  accordionSummary: {
    borderRadius: '5px 5px 0 0', // Apply borderRadius to the top corners
  },
  accordionDetails: {
    borderRadius: '0 0 5px 5px', // Apply borderRadius to the bottom corners
  },
}));

const SendCommandDialog = ({ open, onClose, deviceId }) => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();

  const [savedId, setSavedId] = useState(0);
  const [item, setItem] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const handleSend = useCatch(async () => {
    try {
      let command;
      if (savedId) {
        const response = await fetch(`/api/commands/${savedId}`);
        if (!response.ok) {
          throw new Error(await response.text());
        }
        command = await response.json();
      } else {
        command = item;
      }

      command.deviceId = parseInt(deviceId, 10);

      const response = await fetch('/api/commands/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(command),
      });

      if (response.ok) {
        onClose(); // Close the dialog
        setSuccessMessage(t('commandSentSuccess'));
        setShowSuccessDialog(true);
      } else {
        throw new Error(await response.text());
      }
    } catch (error) {
      throw new Error(`Error sending command: ${error.message}`);
    }
  });

  const validate = () => savedId || (item && item.type);

  return (
    <>
      <Dialog open={open} onClose={onClose} sx={{ borderRadius: 100 }} className={classes.dialog}>
        <DialogTitle sx={{ marginBottom: -3, fontSize: 18, alignItems: 'center' }}>
          <span style={{ flex: '1' }}>{t('commandSend')}</span>
          {' '}
          {/* Title text with flex property */}
          <SendIcon sx={{ color: '#003165', marginLeft: '8px', marginBottom: '-4px' }} />
          {' '}
          {/* SendIcon with custom color */}
        </DialogTitle>

        <DialogContent>
          <Container sx={{ width: 300, borderRadius: 10 }} className={classes.container}>
            <Accordion
              defaultExpanded
              sx={{
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Add box shadow to the Accordion
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
                <Typography sx={{ fontSize: 14 }} variant="subtitle1">
                  {t('requiredCommand')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {/* <SelectField
                value={savedId}
                emptyTitle={t('sharedNew')}
                onChange={(e) => setSavedId(e.target.value)}
                endpoint={`/api/commands/send?deviceId=${deviceId}`}
                titleGetter={(it) => it.description}
                label={t('sharedSavedCommand')}
              /> */}

                <BaseCommandView item={item} setItem={setItem} />

              </AccordionDetails>
            </Accordion>
            <div className={classes.buttons}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={onClose}
              >
                {t('sharedCancel')}
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleSend}
                disabled={!validate()}
              >
                Send
              </Button>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
      <SuccessDialog
        open={showSuccessDialog}
        onClose={() => {
          setShowSuccessDialog(false);
          setSuccessMessage(''); // Optionally clear the message when closing the dialog
        }}
        message={successMessage}
      />
    </>
  );
};

export default SendCommandDialog;
