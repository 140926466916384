import React, { useState, useEffect } from 'react';
import {
  Button, Typography, IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginLayout from './LoginLayout';
import { useTranslation } from '../common/components/LocalizationProvider';
import useQuery from '../common/util/useQuery';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase',
  },
}));

const ExpiredPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const query = useQuery();

  const [userDetails, setUserDetails] = useState(null);

  const handleSubmit = () => {
    // Navigate to the specified route
    navigate('/settings/user/1');
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const email = query.get('email');
      if (email) {
        const response = await fetch(`/api/users/email/${encodeURIComponent(email)}`);
        if (response.ok) {
          const details = await response.json();
          setUserDetails(details);
        }
      }
    };

    fetchUserDetails();
  }, [query]);

  return (
    <LoginLayout>
      <div className={classes.container}>
        <div className={classes.header}>
          <IconButton color="primary" onClick={() => navigate('/login')}>
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} color="primary">
            {t('loginExpired')}
          </Typography>
        </div>
        {userDetails && (
        <div>
          <Typography fontWeight={500}>
            Dear
            {' '}
            {userDetails.name}
            ,
          </Typography>
          <Typography fontSize={14}>
            Authentication failed because your account is expired. If it is due to non-payment, click the button below to renew.
          </Typography>
        </div>
        )}
        <Button
          sx={{ color: '#003165' }}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          fullWidth
        >
          {t('loginPay')}
        </Button>
      </div>
    </LoginLayout>
  );
};

export default ExpiredPage;
